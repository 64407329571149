import Box from "@component/Box";
import CarouselCard1 from "@component/carousel-cards/CarouselCard1";
import Carousel from "@component/carousel/Carousel";
import Container from "@component/Container";
import Navbar from "@component/navbar/Navbar";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const Hero: React.FC = () => {
    // @ts-ignore
    const home = useSelector(state => state.home)
    return (
        <Fragment>
            <Navbar navListOpen={true} />
            <Box bg="gray.white" mb="3.75rem">
                <Container pb="2rem">
                    <Carousel
                        totalSlides={home.sliders.length}
                        visibleSlides={1}
                        infinite={true}
                        autoPlay={true}
                        showDots={true}
                        showArrow={false}
                        spacing="0px"
                    >
                        {home.sliders.map((slider, index) => (
                            <CarouselCard1 key={index} slider={slider}/>
                        ))}
                    </Carousel>
                </Container>
            </Box>
        </Fragment>
    );
};

export default Hero;