import React from "react"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {StyledProductCard1} from "@component/product-cards/ProductCardStyle";

const ProductLoader1 = (props) => (
    <StyledProductCard1 {...props}>
        <SkeletonTheme baseColor="#f3f4f7" highlightColor="#f1f1f1">
            <div className="p-4">
                <Skeleton
                    height="248px"
                    containerClassName="avatar-skeleton"
                />
            </div>
            <p className="mb-6 px-4">
                <Skeleton count={3} />
            </p>
        </SkeletonTheme>
    </StyledProductCard1>
)

export default ProductLoader1
