import Card from "@component/Card";
import Link from "next/link";
import React, {useEffect} from "react";
import Box from "../Box";
import CategorySectionHeader from "../CategorySectionHeader";
import Container from "../Container";
import Grid from "../grid/Grid";
import ProductCard4 from "../product-cards/ProductCard4";
import ProductCard5 from "../product-cards/ProductCard5";
import {useDispatch, useSelector} from "react-redux";
import {getHomeFeatureBands, getHomeTopRatings} from "@store/homeReducer";
import ProductLoader4 from "@component/product-cards/ProductLoader4";
import ProductLoader5 from "@component/product-cards/ProductLoader5";

const TopRatingFeatureBands: React.FC = () => {
    // @ts-ignore
    const home = useSelector(state => state.home)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeFeatureBands({}))
        dispatch(getHomeTopRatings({}))
    }, [])

    return (
        <Box mb="3.75rem">
            <Container>
                <Box>
                    <Grid container spacing={6}>
                        <Grid item lg={6} xs={12}>
                            <CategorySectionHeader
                                iconName="icons/ranking-1"
                                title="Top Ratings"
                                seeMoreLink="/products/section/Top Ratings"
                            />
                            <Card p="1rem">
                                <Grid container spacing={4}>
                                    {home?.topRatings?.length > 0 ? home.topRatings.slice(0, 4).map((product, index) => (
                                        <Grid item md={3} sm={6} xs={6} key={index}>
                                            <Link href={'/product/' + product.slug }>
                                                <a>
                                                    <ProductCard4
                                                        title={product.name}
                                                        rating={product.avg_ratings}
                                                        imgUrl={product.image1}
                                                        price={+product.discount_price}
                                                        reviewCount={product.total_ratings}/>
                                                </a>
                                            </Link>
                                        </Grid>
                                    )) : Array.from({length: 4}).map((_, index) => (
                                        <Grid item md={3} sm={6} xs={6} key={index}>
                                            <Link href={'#!'}>
                                                <a>
                                                    <ProductLoader4/>
                                                </a>
                                            </Link>
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CategorySectionHeader
                                iconName="icons/Group"
                                title="Featured Brands"
                                seeMoreLink="/brands"
                            />
                            <Card p="1rem">
                                <Grid container spacing={4}>
                                    {home?.featureBands?.length > 0 ? home.featureBands?.map((item, index) => (
                                        <Grid item sm={6} xs={12} key={index}>
                                            <Link href={"/products/brand/" + item.name}>
                                                <a>
                                                    <ProductCard5 title={item.name} imgUrl={item.logo_image || ""}/>
                                                </a>
                                            </Link>
                                        </Grid>
                                    )) : Array.from({length: 2}).map((_, index) => (
                                        <Grid item sm={6} xs={12} key={index}>
                                            <Link href={"#!"}>
                                                <a>
                                                    <ProductLoader5/>
                                                </a>
                                            </Link>
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};
export default TopRatingFeatureBands;
