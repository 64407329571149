import Card from "@component/Card";
import React, {useEffect} from "react";
import CategorySectionCreator from "../CategorySectionCreator";
import Grid from "../grid/Grid";
import ProductCard2 from "../product-cards/ProductCard2";
import {useDispatch, useSelector} from "react-redux";
import {getHomeNewArrivals} from "@store/homeReducer";
import ProductLoader2 from "@component/product-cards/ProductLoader2";

const NewArrivals: React.FC = () => {
    // @ts-ignore
    const home = useSelector(state => state.home)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeNewArrivals({}))
    }, [])

    return (
        <CategorySectionCreator
            iconName="icons/new-product-1"
            title="New Arrivals"
            seeMoreLink="/products/section/New Arrivals"
        >
            <Card p="1rem">
                <Grid container spacing={6}>
                    {home.newArrivals.length > 0 ? (
                        home.newArrivals.slice(0, 6).map((product, index) => (
                            <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                                <ProductCard2 title={product.name} imgUrl={product.image1} price={+product.discount_price}
                                              productUrl={'product/' + product.slug}/>
                            </Grid>
                        ))
                    ) : (
                        Array.from({length: 6}).map((_, index) => (
                                <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                                    <ProductLoader2/>
                                </Grid>
                            )
                        )
                    )}
                </Grid>
            </Card>
        </CategorySectionCreator>
    );
};

export default NewArrivals;
