import React, {useEffect} from "react";
import Card from "../Card";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import Icon from "../icon/Icon";
import {H4, SemiSpan} from "../Typography";
import {useDispatch, useSelector} from "react-redux";
import {getHomeServices} from "@store/homeReducer";

const Services: React.FC = () => {
    // @ts-ignore
    const home = useSelector(state => state.home)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeServices())
    }, [])

    return (
        <Container mb="70px">
            <Grid container spacing={6}>
                {home?.services?.map((item, ind) => (
                    <Grid item lg={3} md={6} xs={12} key={ind}>
                        <FlexBox
                            as={Card}
                            flexDirection="column"
                            alignItems="center"
                            p="3rem"
                            height="100%"
                            borderRadius={8}
                            boxShadow="border"
                            hoverEffect
                        >
                            <FlexBox
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="300px"
                                bg="gray.200"
                                size="64px"
                            >
                                <Icon color="secondary" size="1.75rem">
                                    {item.icon}
                                </Icon>
                            </FlexBox>
                            <H4 mt="20px" mb="10px" textAlign="center">
                                {item.title}
                            </H4>
                            <SemiSpan textAlign="center">
                                {item.subtitle}
                            </SemiSpan>
                        </FlexBox>
                    </Grid>
                ))}
            </Grid>
            {/* </Card> */}
        </Container>
    );
};


export default Services;
