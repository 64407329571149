import React from "react";
import Button from "../buttons/Button";
import Typography from "../Typography";
import {StyledCarouselCard1} from "./CarouselCardStyle";
import Link from 'next/link'

export interface CarouselCard1Props {
    slider: any,
}

const CarouselCard1: React.FC<CarouselCard1Props> = ({slider}) => {
  return (
    <StyledCarouselCard1>
        <div>
            <h1 className="title">{slider?.title}</h1>
            <Typography color="secondary.main" mb="1.35rem">{slider?.subtitle}</Typography>
            <Link href={'products/slider/' + slider.slug}>
                <Button
                    className="button-link"
                    variant="contained"
                    color="primary"
                    p="1rem 1.5rem"
                    disabled={!slider.slug}
                >
                    View Products
                </Button>
            </Link>

        </div>

        <div className="image-holder w-96 sm:w-120" style={{maxHeight: 350}}>
            <img
                src={slider?.image}
                style={{maxWidth: '100%'}}
                alt=""
            />
        </div>
    </StyledCarouselCard1>
  );
};

export default CarouselCard1;
