import HoverBox from "@component/HoverBox";
import {H4} from "@component/Typography";
import Link from "next/link";
import React from "react";
import {useSelector} from "react-redux";

export interface ProductCard2Props {
  imgUrl: string;
  title: string;
  price: number;
  productUrl: string;
}

const ProductCard2: React.FC<ProductCard2Props> = ({
  imgUrl,
  title,
  price,
  productUrl,
}) => {
  // @ts-ignore
  const settings = useSelector(state => state.settings.data)
  return (
    <Link href={productUrl}>
      <a>
        <HoverBox borderRadius={8} mb="0.5rem">
          <div className="h-48 p-4 flex justify-center items-center">
            <img src={imgUrl} style={{maxHeight:'100%'}} alt={""}
            />
          </div>
        </HoverBox>
        <H4 fontWeight="600" fontSize="14px" mb="0.25rem">
          {title.length > 30 ? `${title.substring(0, 30)}...` : title}
        </H4>
        <H4 fontWeight="600" fontSize="14px" color="primary.main">
          {settings.currency} {Math.ceil(price).toLocaleString()}
        </H4>
      </a>
    </Link>
  );
};

export default ProductCard2;
