import Hero from "@component/home/Hero";
import AppLayout from "../components/layout/AppLayout";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getHomeFeatureCategories, getHomeSliders} from "@store/homeReducer";
import FlashDeals from "@component/home/FlashDeals";
import Categories from "@component/home/Categories";
import TopCategories from "@component/home/TopCategories";
import TopRatingFeatureBands from "@component/home/TopRatingAndFeatureBands";
import Services from "@component/home/Services";
import NewArrivals from "@component/home/NewArrivals";
import BigDiscounts from "@component/home/BigDiscounts";
import FeatureCategory from "@component/home/FeatureCategory";
import MoreForYou from "@component/home/MoreForYou";
import Banner from "@component/home/Banner";

const IndexPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHomeSliders())
        dispatch(getHomeFeatureCategories())
    }, [])
    // @ts-ignore
    const home = useSelector(state => state.home)
    let featured = home.featureCategories || []
    return (
        <main>
            <Hero/>
            <FlashDeals/>
            <TopCategories/>
            <TopRatingFeatureBands/>
            <NewArrivals/>
            <BigDiscounts/>
            {featured.length > 0 && (
                <FeatureCategory category={featured[0]}/>
            )}
            <Banner/>
            {featured.length > 1 && (
                <FeatureCategory category={featured[1]}/>
            )}
            {featured.length > 2 && (
                <FeatureCategory category={featured[2]}/>
            )}
            <Categories/>
            <MoreForYou/>
            <Services/>
        </main>
    );
};

IndexPage.layout = AppLayout;

export default IndexPage;
