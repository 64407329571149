import Link from "next/link";
import React from "react";
import Card from "../Card";
import CategorySectionHeader from "../CategorySectionHeader";
import Container from "../Container";
import Grid from "../grid/Grid";
import Typography from "../Typography";
import {useSelector} from "react-redux";

const Categories: React.FC = () => {
    // @ts-ignore
    const categories = useSelector(state => state.category.categories)
    return (
        <Container mb="70px">
            <CategorySectionHeader
                title="Categories"
                iconName="icons/categories"
                seeMoreLink="/categories"
            />

            <Grid container spacing={6}>
                {categories?.slice(0, 12).map((category, ind) => (
                    <Grid item lg={2} md={3} sm={4} xs={12} key={ind}>
                        <Link href={"/products/category/" + category.slug}>
                            <a>
                                <Card
                                    display="flex"
                                    alignItems="center"
                                    p="0.75rem"
                                    boxShadow="small"
                                    borderRadius={8}
                                    hoverEffect
                                >
                                    <div className="h-12 w-12 p-2 flex justify-center items-center">
                                        <img src={'/assets/images/' + category.icon + '.svg'}
                                             style={{maxHeight: '100%'}} alt={""}
                                        />
                                    </div>
                                    <Typography fontWeight="600" fontSize="14px" ml="10px">
                                        {category.name}
                                    </Typography>
                                </Card>
                            </a>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};
export default Categories;
