import Card from "@component/Card";
import Carousel from "@component/carousel/Carousel";
import useWindowSize from "@hook/useWindowSize";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import CategorySectionCreator from "../CategorySectionCreator";
import ProductCard6 from "../product-cards/ProductCard6";
import {useDispatch, useSelector} from "react-redux";
import {getHomeTopCategories} from "@store/homeReducer";
import ProductLoader6 from "@component/product-cards/ProductLoader6";

const TopCategories: React.FC = () => {
    const [visibleSlides, setVisibleSlides] = useState(3);
    const width = useWindowSize();

    useEffect(() => {
        if (width < 650) setVisibleSlides(1);
        else if (width < 950) setVisibleSlides(2);
        else setVisibleSlides(3);
    }, [width]);

    // @ts-ignore
    const home = useSelector(state => state.home)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeTopCategories({}))
    }, [])

    return (
        <CategorySectionCreator
            iconName="icons/categories"
            title="Top Categories"
            seeMoreLink="/categories"
        >
            <Carousel totalSlides={home.topCategories?.length > 0 ? home.topCategories?.length : 3} visibleSlides={visibleSlides}>
                {home.topCategories?.length > 0 ? home.topCategories.map((category, index) => (
                    <Link href={`/products/category/${category.slug}`} key={index}>
                        <a>
                            <Card p="1rem">
                                <ProductCard6 title={category.name} subtitle={""} imgUrl={category.image ?? '/logo.png'}/>
                            </Card>
                        </a>
                    </Link>
                )) : Array.from({length: 3}).map((_, index) => (
                    <a key={index}>
                        <Card p="1rem">
                            <ProductLoader6/>
                        </Card>e
                    </a>
                ))}
            </Carousel>
        </CategorySectionCreator>
    );
};
export default TopCategories;
