import React from "react";
import Card from "@component/Card";
import HoverBox from "@component/HoverBox";
import Skeleton from "react-loading-skeleton";

const ProductLoader6 = () => {
    return (
        <Card position="relative">
            <HoverBox position="relative" height="120px" borderRadius={8}>
                <Skeleton
                    height="120px"
                    containerClassName="avatar-skeleton"
                />
            </HoverBox>
        </Card>
    )
}
export default ProductLoader6