import Box from "@component/Box";
import Card from "@component/Card";
import Carousel from "@component/carousel/Carousel";
import FlexBox from "@component/FlexBox";
import HoverBox from "@component/HoverBox";
import {H4} from "@component/Typography";
import useWindowSize from "@hook/useWindowSize";
import Link from "next/link";
import React, {useEffect, useState} from "react";
import CategorySectionCreator from "../CategorySectionCreator";
import {useDispatch, useSelector} from "react-redux";
import {getHomeBigDiscounts} from "@store/homeReducer";
import Skeleton from "react-loading-skeleton";

const BigDiscounts: React.FC = () => {
    const [visibleSlides, setVisibleSlides] = useState(6);
    const width = useWindowSize();

    useEffect(() => {
        if (width < 370) setVisibleSlides(1);
        else if (width < 650) setVisibleSlides(2);
        else if (width < 950) setVisibleSlides(4);
        else setVisibleSlides(5);
    }, [width]);


    // @ts-ignore
    const home = useSelector(state => state.home)
    // @ts-ignore
    const settings = useSelector(state => state.settings.data)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeBigDiscounts({}))
    }, [])

    return (
        <CategorySectionCreator
            iconName="icons/gift"
            title="Big Discounts"
            seeMoreLink="/products/section/Big Discounts"
        >
            <Box my="-0.25rem">
                {home.bigDiscounts.length > 0 ? (
                    <Carousel totalSlides={home.bigDiscounts.length} visibleSlides={visibleSlides}>
                        {home.bigDiscounts.map((item, index) => (
                            <Box py="0.25rem" key={index}>
                                <Card p="1rem">
                                    <Link href={`/product/${item.slug}`}>
                                        <a>
                                            <HoverBox borderRadius={8} mb="0.5rem">
                                                <div className="h-40 p-4 flex justify-center items-center">
                                                    <img src={item.image1} style={{maxHeight: '100%'}} alt={""}
                                                    />
                                                </div>
                                            </HoverBox>
                                            <H4 fontWeight="600" fontSize="14px" mb="0.25rem">
                                                {item.name.length > 30 ? `${item.name.substring(0, 30)}...` : item.name}
                                            </H4>

                                            <FlexBox>
                                                <H4
                                                    fontWeight="600"
                                                    fontSize="14px"
                                                    color="primary.main"
                                                    mr="0.5rem"
                                                >
                                                    {settings.currency} {Math.ceil(+item.discount_price).toLocaleString()}
                                                </H4>
                                                {+item.discount_price !== +item.price && (
                                                    <H4 fontWeight="600" fontSize="14px" color="text.muted">
                                                        <del> {settings.currency} {Math.ceil(+item.price).toLocaleString()}</del>
                                                    </H4>
                                                )}
                                            </FlexBox>
                                        </a>
                                    </Link>
                                </Card>
                            </Box>
                        ))}
                    </Carousel>
                ) : (
                    <Carousel totalSlides={6} visibleSlides={visibleSlides}>
                        {Array.from({length: 6}).map((_, index) => (
                            <Box py="0.25rem" key={index}>
                                <Card p="1rem">
                                    <Link href={`#!`}>
                                        <a>
                                            <HoverBox borderRadius={8} mb="0.5rem">
                                                <div className="h-40 p-4 flex justify-center items-center">
                                                    <Skeleton
                                                        height="100%"
                                                        containerClassName="avatar-skeleton"
                                                    />
                                                </div>
                                            </HoverBox>
                                            <Skeleton count={3}/>
                                        </a>
                                    </Link>
                                </Card>
                            </Box>
                        ))}
                    </Carousel>

                )}
            </Box>
        </CategorySectionCreator>
    );
};

export default BigDiscounts;
