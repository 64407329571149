import Grid from "@component/grid/Grid";
import LazyImage from "@component/LazyImage";
import Link from "next/link";
import React, {useEffect} from "react";
import Container from "../Container";
import {useDispatch, useSelector} from "react-redux";
import {getHomeBanners} from "@store/homeReducer";

const Banner: React.FC = () => {
    // @ts-ignore
    const banners = useSelector(state => state.home.banners)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeBanners())
    }, [])

    if(!banners.small_banner) {
        return <></>
    }

    return (
        <Container mb="70px">
            <Grid container spacing={5}>
                <Grid item xs={12} md={4}>
                    <Link href={"/product/" + banners?.small_banner_product?.slug}>
                        <a>
                            <LazyImage
                                src={banners.small_banner || ''}
                                height={342}
                                width={385}
                                layout="responsive"
                                objectFit="contain"
                                alt="banner"
                            />
                        </a>
                    </Link>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Link href={"/product/" + banners?.big_banner_product?.slug}>
                        <a>
                            <LazyImage
                                src={banners?.big_banner}
                                height={342}
                                width={790}
                                layout="responsive"
                                objectFit="contain"
                                alt="banner"
                            />
                        </a>
                    </Link>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Banner;
