import React from "react";
import HoverBox from "@component/HoverBox";
import Skeleton from "react-loading-skeleton";

const ProductLoader4 = () => {
    return (
        <div>
            <HoverBox borderRadius={8} mb="0.5rem">
                <div className="h-36 p-4 flex justify-center items-center">
                    <Skeleton
                        height="100%"
                        containerClassName="avatar-skeleton"
                    />
                </div>
            </HoverBox>
            <p className="mb-2">
                <Skeleton/>
            </p>
        </div>
    )
}
export default ProductLoader4