import React, {useEffect} from "react";
import CategorySectionHeader from "../CategorySectionHeader";
import Container from "../Container";
import Grid from "../grid/Grid";
import ProductCard1 from "../product-cards/ProductCard1";
import {useDispatch, useSelector} from "react-redux";
import {getMoreProducts} from "@store/homeReducer";
import ProductLoader1 from "@component/product-cards/ProductLoader1";

const MoreForYou: React.FC = () => {
    // @ts-ignore
    const home = useSelector(state => state.home)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getMoreProducts({}))
    }, [])

    return (
        <Container mb="70px">
            <CategorySectionHeader title="More For You" seeMoreLink="/products/section/more" />
            <Grid container spacing={6}>
                {home.more?.length > 0 ?
                    home.more.map((product, ind) => (
                            <Grid item lg={3} md={4} sm={6} xs={12} key={ind}>
                                <ProductCard1
                                    hoverEffect
                                    product={product} />
                            </Grid>
                        ))
                    : Array.from({length: 12}).map((_, index) => (
                        <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                            <ProductLoader1/>
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    );
};

export default MoreForYou;
