import LazyImage from "@component/LazyImage";
import React, {useEffect, useState} from "react";
import Box from "../Box";
import CategorySectionHeader from "../CategorySectionHeader";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import Hidden from "../hidden/Hidden";
import ProductCard1 from "../product-cards/ProductCard1";
import StyledProductCategory from "./ProductCategoryStyle";
import {fetchCategoryBands, fetchProducts} from "../../helpers/backend_helper";
import {useRouter} from "next/router";
import ProductLoader1 from "@component/product-cards/ProductLoader1";
import Skeleton from "react-loading-skeleton";

const FeatureCategory = ({category}) => {
    const router = useRouter()
    const [selected, setSelected] = useState("");
    const [products, setProducts] = useState([])
    const [brands, setBrands] = useState([])
    useEffect(() => {
        fetchCategoryBands(category.slug).then(result => {
            setBrands(result)
        })
    }, [])

    useEffect(() => {
        let data = {categories: JSON.stringify([category.slug]), page: 1, per_page: 6, brands: undefined}
        if (selected) {
            data.brands = JSON.stringify([selected])
        }
        fetchProducts(data).then(({success, data}) => {
            if (success === true) {
                setProducts(data.data)
            }
        })
    }, [selected])

    return (
        <Container mb="80px">
            <FlexBox>
                <Hidden down={768} mr="1.75rem">
                    <Box shadow={6} borderRadius={10} padding="1.25rem" bg="white">
                        {brands?.length > 0 ?
                            brands.slice(0, 8).map((brand, ind) => (
                                <StyledProductCategory
                                    key={ind}
                                    id={brand.name}
                                    mb="0.75rem"
                                    bg={selected.match(brand.name) ? "white" : "gray.100"}
                                    shadow={selected.match(brand.name) ? 4 : null}
                                    onClick={() => setSelected(brand.name)}
                                >
                                    <LazyImage
                                        width="20px"
                                        height="20px"
                                        layout="fixed"
                                        objectFit="contain"
                                        src={brand.logo_image ?? "/assets/images/icons/logo.png"}
                                        alt="apple"
                                    />
                                    <span className="product-category-title">{brand.name}</span>
                                </StyledProductCategory>
                            )) : <Skeleton count={8} height={40} className="mb-2"/>
                        }
                        <StyledProductCategory
                            id="all"
                            mt="4rem"
                            bg={selected.match("all") ? "white" : "gray.100"}
                            shadow={selected.match("all") ? 4 : null}
                            onClick={() => router.push("/products/category/" + category.slug)}
                        >
                            <span id="all" className="product-category-title show-all">
                                View All Products
                            </span>
                        </StyledProductCategory>
                    </Box>
                </Hidden>

                <Box flex="1 1 0" minWidth="0px">
                    <CategorySectionHeader title={category.name} seeMoreLink={"/products/category/" + category.slug}/>
                    <Grid container spacing={6}>
                        {products?.length > 0 ?
                            products?.map((product, ind) => (
                                <Grid item lg={4} sm={6} xs={12} key={ind}>
                                    <ProductCard1
                                        hoverEffect
                                        product={product}
                                    />
                                </Grid>
                            ))
                            : Array.from({length: 6}).map((_, index) => (
                                <Grid item lg={4} sm={6} xs={12} key={index}>
                                    <ProductLoader1/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </FlexBox>
        </Container>
    );
};

export default FeatureCategory;
