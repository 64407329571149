import Box from "@component/Box";
import HoverBox from "@component/HoverBox";
import {H4} from "@component/Typography";
import React from "react";

export interface ProductCard5Props {
  imgUrl: string;
  title: string;
}

const ProductCard5: React.FC<ProductCard5Props> = ({ imgUrl, title }) => {
  return (
    <Box>
      <HoverBox borderRadius={5} mb="0.5rem">
          <div className="h-36 p-6 flex justify-center items-center">
              <img src={imgUrl} style={{maxHeight:'100%'}} alt={""}
              />
          </div>
      </HoverBox>
        {title && (
            <H4 fontSize="14px" fontWeight="600" textAlign="center">
                {title}
            </H4>
        )}
    </Box>
  );
};

export default ProductCard5;
