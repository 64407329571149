import Box from "@component/Box";
import React, {useEffect, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Carousel from "../carousel/Carousel";
import CategorySectionCreator from "../CategorySectionCreator";
import ProductCard1 from "../product-cards/ProductCard1";
import {useDispatch, useSelector} from "react-redux";
import ProductLoader1 from "@component/product-cards/ProductLoader1";
import {getHomeFlashDeals} from "@store/homeReducer";

const FlashDeals: React.FC = () => {
    const [visibleSlides, setVisibleSlides] = useState(4);
    const width = useWindowSize();
    useEffect(() => {
        if (width < 500) setVisibleSlides(1);
        else if (width < 650) setVisibleSlides(2);
        else if (width < 950) setVisibleSlides(3);
        else setVisibleSlides(4);
    }, [width]);

    // @ts-ignore
    const home = useSelector(state => state.home)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getHomeFlashDeals({}))
    }, [])

    return (
        <CategorySectionCreator
            iconName="icons/light"
            title="Flash Deals"
            seeMoreLink="/products/section/Flash Deals"
        >
            <Box mt="-0.25rem" mb="-0.25rem">
                <Carousel totalSlides={10} visibleSlides={visibleSlides}>
                    {home.flashDeals?.length > 0 ? home.flashDeals.map((item, index) => (
                        <Box py="0.25rem" key={index}>
                            <ProductCard1 product={item}/>
                        </Box>
                    )) : Array.from({length: 6}).map((_, index) => (
                        <Box py="0.25rem" key={index}>
                            <ProductLoader1/>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </CategorySectionCreator>
    );
};
export default FlashDeals;
